import request from '@/utils/request'

// 商家分账汇总数据
export function ledgerSummaryDetail(params) {
  return request.post('/summary/ledgerSummaryDetail', params)
}

// 获取商家数据总览
export function summaryOverviewDetail(params) {
  return request.post('/summary/summaryOverviewDetail', params)
}