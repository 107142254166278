<template>
  <div class="businessDataCollect">
    <div>
      <span v-if="myBrand" class="mr_20">{{myBrand.name}}</span>
      <span v-else-if="myComp" class="mr_20">{{myComp.name}}</span>
      <span v-if="!myBrand && !myComp && myShop" class="mr_20">{{myShop.name}}</span>
    </div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div class="search-group-wrap flex">
          <!-- <el-row :gutter="20"> -->
            <!-- <el-col :span="6"> -->
              <el-form-item label="商家"
                  v-if="myBrand || myComp">
                <el-select
                  style="width: 100%"
                  v-model="searchForm.businessId"
                  :remote-method="businessRemoteMethod"
                  :loading="businessLoading"
                  placeholder="选择商家"
                  clearable
                  filterable
                  remote
                  @focus="businessRemoteMethod()"
                  @change="getSummaryOverviewDetail"
                >
                  <el-option
                    v-for="item in businessList"
                    :key="item.id"
                    :label="item.full_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            <!-- </el-col> -->
            <!-- <el-col :span="6"> -->
              <el-form-item label="日期">
                <el-date-picker
                  style="width: 100%"
                  v-model="collect_Time"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="setDateRange"
                  @change="getSummaryOverviewDetail"
                >
                </el-date-picker>
              </el-form-item>
            <!-- </el-col> -->
          <!-- </el-row> -->
          <div class="ml_20">
            <el-button type="primary" size="small"
                @click="getSummaryOverviewDetail">搜索</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="collect-table">
      <div class="title flex">
        <h3>签约数据</h3>
        <span class="ml_20">按生效时间查询</span>
      </div>
      <el-table 
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column width="140px" prop="bill_count" label="签约数" />
        <el-table-column width="140px" prop="bill_contract_amount" label="签约金额" />
      </el-table>

      <div class="title flex">
        <h3>账单总览</h3>
        <span class="ml_20">按分期账单日期查询</span>
      </div>
      <el-table 
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column width="140px" prop="should_repayment_principal" label="应还款" />
        <el-table-column width="140px" prop="should_repayment_remain_principal" label="待还款" />
        <!-- <el-table-column prop="repaid_principal" label="已还款" /> -->

        <!-- <el-table-column prop="should_repayment_interest" label="应还管理费" /> -->
        <!-- <el-table-column prop="should_repayment_interest_business_payer" label="应还平台管理费(商家贴息)" /> -->
      </el-table>

      <div class="title flex">
        <h3>还款总览</h3>
        <span class="ml_20">按还款交易时间查询</span>
      </div>
      <el-table style1="width: 100%;"
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column width="140px" prop="repaid_amount" label="总收入" />
        <el-table-column width="140px" prop="repaid_principal" label="还款本金" />
        <el-table-column width="140px" prop="repaid_interest" label="商家管理费" 
          v-if="has_repaid_interest" />
        <el-table-column width="140px" prop="repaid_interest_business_payer" label="商家贴息" 
          v-if="has_repaid_interest_business_payer" />
        <el-table-column width="140px" prop="repaid_refund_business_income" label="退款手续费" />
      </el-table>

      

     <!--  <el-table
        :data="tableData"
        header-cell-class-name="table_header"
        border
      >
        <el-table-column prop="bill_count" label="账单总数(签约生效中)" />
        <el-table-column prop="bill_contract_amount" label="账单总金额(签约生效中)" />
        <el-table-column prop="should_repayment_principal" label="应还本金" />
        <el-table-column prop="should_repayment_remain_principal" label="应还剩余本金" />
        <el-table-column prop="should_repayment_interest" label="应还管理费" />
        <el-table-column prop="should_repayment_interest_business_payer" label="应还平台管理费(商家贴息)" />
        <el-table-column prop="repaid_amount" label="已还总金额" />
        <el-table-column prop="repaid_refund_business_income" label="已还退课费(商家收入)" />
        <el-table-column prop="repaid_interest_business_payer" label="已还平台管理费(商家贴息)" />
        <el-table-column prop="repaid_interest" label="已还商家管理费" />
        <el-table-column prop="repaid_principal" label="已还本金" />
      </el-table> -->
    </div>
  </div>
</template>

<script>
import {summaryOverviewDetail} from '@/api/collect.js'
import {suggestBusinessList} from '@/api/business.js'
import { getMyBrand, getMyComp, getMyShop } from '@/utils/index.js'
export default {
  name: 'businessDataCollect',

  data() {
    return {
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),

      searchForm: {
        page: 1,
        per_page: 10,
        seniorId: '',
        entityId: '',
        businessId: '',
      },
      loading: false,
      total: 0,
      tableData: [],
      has_repaid_interest: false,
      has_repaid_interest_business_payer: false,

      businessLoading: false,
      businessList: [],
      baseBusinessList: [],

      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      collect_Time: [],
      repaid_interest:""
    };
  },

  async mounted() {
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness()
  },

  methods: {
    initData() {
      this.getSummaryOverviewDetail();
    },
    async getSummaryOverviewDetail() {
      this.searchForm.seniorId = ''
      this.searchForm.entityId = ''
      if(!this.myBrand && !this.myComp && this.myShop){
        this.searchForm.businessId = this.myShop.id
      }
      if(!this.searchForm.businessId){
        if(this.myBrand){
          this.searchForm.seniorId = this.myBrand.id
        }else if(this.myComp){
          this.searchForm.entityId = this.myComp.id
        }
      }

      if (this.collect_Time && this.collect_Time.length == 2) {
        this.searchForm.startTime = this.collect_Time[0] + " " + "00:00:00";
        this.searchForm.endTime   = this.collect_Time[1] + " " + "23:59:59";
      } else {
        this.searchForm.startTime = "";
        this.searchForm.endTime   = "";
      }

      const { code, data } = await summaryOverviewDetail(this.searchForm);
      this.loading = true;
      if (code == 200) {
        this.loading = false;
        let has_repaid_interest = false
        let has_repaid_interest_business_payer = false
        if(data[0]){
          has_repaid_interest = Number(data[0]['repaid_interest']) > 0
          has_repaid_interest_business_payer = Number(data[0]['repaid_interest_business_payer']) > 0
        }
        this.has_repaid_interest = has_repaid_interest
        this.has_repaid_interest_business_payer = has_repaid_interest_business_payer

        data.map(el=>{
          this.repaid_interest = el.repaid_interest
          if(Number(el.repaid_interest_business_payer) > 0){
            el.repaid_interest_business_payer = "-"+el.repaid_interest_business_payer
          }
        })
        this.tableData = data;
      } else {
        this.loading = false;
      }
    },

    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query)
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const {code, data} = await suggestBusinessList({
        keyword: keyword || ""
      })
      if(code == 200){
        return data
      }
      return []
    },
  },
};
</script>

<style lang="scss" scoped>
.businessDataCollect {
  .collect-table {
    min-width: 800px;
    max-width: 1200px;
    /deep/ .table_header {
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
      width: 140px;
      min-width: 120px;
      max-width: 160px;
    }

    .title{
      align-items: baseline;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}
</style>